import React from 'react';
import { PageApproche } from '@src/ui';
import { usePageList } from '@src/hooks/page-list';

const Page: React.FC = () => {
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === 'approche');
  return <PageApproche image={currentPageData?.image} />;
};

export default Page;
